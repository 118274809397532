import { FC, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useInView } from "react-intersection-observer";

import { useRuHost } from "../../hooks/useRuHost";
import { OfferDataResponse } from "../../services/api/apiOfferSlice";
import { ProductData } from "../../types/products";
import { OfferPriceProps } from "../../types/uikit";
import { BuyModal } from "../BuyModal";
import OfferPrice from "../OfferPrice";
import styles from "./ProductListItem.module.sass";

type Props = {
  product: ProductData;
  welcomeOffer?: OfferDataResponse | null | undefined;
};

const ProductListItem: FC<Props> = ({ product, welcomeOffer }) => {
  const [imageSrc, setImageSrc] = useState<string>("image");
  const {
    name,
    product_id,
    bonus_package,
    configuration,
    package: productPackage,
  } = product;
  const isRuHost = useRuHost();

  useEffect(() => {
    const loadImage = async () => {
      try {
        let dynamicImage;
        if (isRuHost) {
          dynamicImage = await import(
            `../../assets/images/products/${product_id}_ru.png`
          );
        } else {
          dynamicImage = await import(
            `../../assets/images/products/${product_id}.png`
          );
        }
        setImageSrc(dynamicImage.default);
      } catch (error) {
        let templateCoinsImage;
        if (isRuHost) {
          templateCoinsImage = await import(
            `../../assets/images/products/template_ru.png`
          );
        } else {
          templateCoinsImage = await import(
            `../../assets/images/products/template.png`
          );
        }
        setImageSrc(templateCoinsImage.default);
      }
    };

    loadImage();
  }, [window.location.host, product_id]);

  // const { ref: refObServer, inView } = useInView({
  //   threshold: 0.25,
  //   triggerOnce: true,
  // });

  // const hasWelcomeOffer = useMemo(() => !!welcomeOffer, [welcomeOffer]);
  // const { data } = welcomeOffer || {};
  // const { packs } = data || {};

  // const { old_value, new_value } =
  //   packs?.find((packItem) => packItem.id === product_id) || {};

  let bonusPercent = configuration?.game_package?.hard_bonus_mul;
  bonusPercent = bonusPercent && bonusPercent * 100;

  const bonus = bonus_package?.finalized.hard;

  const priceBased = productPackage?.base.hard;
  const priceFinalized = productPackage?.finalized.hard;

  const offerProps: OfferPriceProps = {
    newPriceValue: priceFinalized,
    oldPriceValue: priceFinalized !== priceBased ? priceBased : undefined,
    isDiscountOfferPrice: !!bonusPercent,
    productNameForPrice: product?.package?.finalized.hard,
  };

  return (
    <div className={styles.wrapper}>
      {bonusPercent !== 0 && (
        <div className={styles.upperBonus}>
          <span className={styles.boldPercent}>{bonusPercent}%</span> WEB-STORE
          BONUS
        </div>
      )}
      <div className={styles.imageInfo}>
        {/* <div className={styles.imageInfo} ref={refObServer}> */}
        {/* {inView ? ( */}
        <div className={isRuHost ? styles.imageForRu : styles.image}>
          <img
            src={imageSrc}
            className="image"
            alt={name}
            width="100%"
            height="100%"
            loading="lazy"
          />
        </div>
        {/* ) : (
          <div className={styles.skeleton}>
            <ContentLoader
              width="100%"
              height="100%"
              backgroundColor="rgba(0, 0, 0, 0.2)"
              foregroundColor="rgba(255, 255, 255, 0.1)"
            >
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
            </ContentLoader>
          </div>
        )} */}

        <div className={styles.info + `${!bonus ? " jcc" : ""}`}>
          <OfferPrice offerProps={offerProps} isBonus={!!bonusPercent} />
          {!!bonus && (
            <div className={styles.bonusWrapper}>
              BONUS <br />
              <span className={styles.bonus}>+{bonus}</span>
            </div>
          )}
          {/* {hasWelcomeOffer && (
            <div className={styles.triangle}>
              <span className={styles.increase}>X2</span>
            </div>
          )} */}
        </div>
      </div>
      <BuyModal
        product={product}
        productImage={imageSrc}
        btnClass={styles.btn}
        offerProps={offerProps}
      />
    </div>
  );
};

export default ProductListItem;
