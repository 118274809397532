import { FC, useRef, useState } from "react";

import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow_icon.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { UserPlayer } from "../../types/user";
import styles from "./Select.module.sass";

type Props = {
  onChange: (value: UserPlayer) => void;
  value?: UserPlayer;
  isError: boolean;
  isMiniVersion?: boolean;
};

export const Select: FC<Props> = ({
  value,
  onChange,
  isError,
  isMiniVersion,
}) => {
  const { translate } = useTranslate();
  const [isActive, setActive] = useState(false);
  const ref = useRef(null);
  const { playersSelect: players } = useTypedSelector((state) => state.player);
  // const isGiftForFirstPurchase =
  //   value?.web_first_purchase_reward !== "available";

  // const { data: offerResultData } = useGetOffersQuery("");
  // const { items } = offerResultData?.data || {};

  // const offerData = useMemo(() => {
  //   return items
  //     ?.map((offer) => {
  //       const filteredPacks = offer.data.packs?.filter(
  //         (pack) => pack.available > 0,
  //       );
  //       if (filteredPacks && filteredPacks.length > 0) {
  //         return {
  //           server_id: offer.server_id,
  //           end_time: offer.data.end_time,
  //           user_id: offer.data.client_id,
  //         };
  //       }
  //       return null;
  //     })
  //     ?.reduce((acc, offer) => {
  //       if (offer) {
  //         acc[offer?.server_id] = {
  //           end_time: offer.end_time,
  //           user_id: offer.user_id,
  //         };
  //       }

  //       return acc;
  //     }, {} as Record<string, SelectorOfferData>);
  // }, [items]);

  const handleSetActive = () => {
    setActive(!isActive);
  };

  const handleChange = (value: UserPlayer) => () => {
    onChange(value);
    setActive(false);
  };

  useOnClickOutside(ref, () => setActive(false));
  const onKeyDown = (
    fn: () => void,
    e: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (e.key === "Enter" || e.key === "Space") {
      fn();
    }
  };

  // TODO: ADD LOADING SELECT
  return (
    <div ref={ref} className={styles.wrapper}>
      <div
        className={`${
          isActive ? styles.isActive : isError ? styles.isError : ""
        } ${isMiniVersion ? styles.isMiniVersion : ""} ${
          value ? styles.isSelected : ""
        } ${styles.selector}`}
        onClick={handleSetActive}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => onKeyDown(handleSetActive, e)}
      >
        <div
          className={`${isMiniVersion ? styles.isMiniVersion : ""} ${
            value ? styles.isSelected : ""
          } ${styles.selectorValue}`}
        >
          {value?.server_name
            ? value.server_name
            : translate("$CONFIRM_WEB_BUY_HEADER_DESCR")}
        </div>
        {isMiniVersion && (
          <div className={styles.selectorName}>
            {/* {isGiftForFirstPurchase && <span> (G) </span>} */}
            {value?.nickname}
          </div>
        )}
        {/* <div className={styles.selectorSticker}>
          {isGiftForFirstPurchase && <span> (G) </span>}
        </div> */}
        <div
          className={`${isActive ? styles.isActive : ""} ${
            isMiniVersion ? styles.isMiniVersion : ""
          } ${styles.selectorDirection}`}
        >
          <ArrowIcon className={styles.arrowIcon} />
        </div>
      </div>
      {isActive && (
        <div
          className={`${isMiniVersion ? styles.isMiniVersion : ""} ${
            styles.options
          }`}
        >
          {players?.map((player, index) => {
            // const offer =
            //   offerData && offerData[player.server_id]
            //     ? offerData && offerData[player.server_id]
            //     : null;
            // const hasWelcomeOffer = !!offer;

            return (
              <div
                onClick={handleChange(player)}
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === "Space") {
                    handleChange(player);
                  }
                }}
                key={index}
                role="button"
                tabIndex={0}
                className={styles.optionWrapper}
              >
                <div
                  className={`${isMiniVersion ? styles.isMiniVersion : ""} ${
                    styles.optionItem
                  }`}
                >
                  <div className={styles.optionGroup}>
                    <div
                      className={`${
                        isMiniVersion ? styles.isMiniVersion : ""
                      } ${styles.optionServer}`}
                    >
                      {player.server_name}
                    </div>
                    {/* {hasWelcomeOffer && (
                      <div className={styles.optionOffer}>
                        <div className={styles.optionOfferBlock}>x2</div>
                        <CountdownTimerWelcome
                          initialTime={
                            (offer?.end_time || dayJsInstance().utc().unix()) -
                            dayJsInstance().utc().unix()
                          }
                        />
                      </div>
                    )} */}
                  </div>
                  <div
                    className={`${isMiniVersion ? styles.isMiniVersion : ""} ${
                      styles.optionPlayer
                    }`}
                  >
                    {/* {isGiftForFirstPurchase && <span> (G) </span>} */}
                    {player.nickname}
                  </div>
                </div>
                {/* {hasWelcomeOffer && (
                  <div className={styles.optionItemOffer}>
                    <div className={styles.optionItemOfferGroup}>
                      <label className={styles.optionItemOfferLabel}>
                        {translate("$WEB_BUY_EVENT_TIMER")}
                      </label>
                      <CountdownTimerWelcome
                        initialTime={
                          offer && offer?.end_time
                            ? (offer?.end_time ||
                                dayJsInstance().utc().unix()) -
                              dayJsInstance().utc().unix()
                            : 0
                        }
                      />
                    </div>
                    <div className={styles.optionOfferBlock}>x2</div>
                  </div>
                )} */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
