import { ReactComponent as DiscordIcon } from "../assets/images/icons/socials-discord.svg";
import { ReactComponent as FacebookIcon } from "../assets/images/icons/socials-facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/images/icons/socials-instagram.svg";
import { ReactComponent as LinkedinIcon } from "../assets/images/icons/socials-linkedin.svg";
import { ReactComponent as RedditIcon } from "../assets/images/icons/socials-reddit.svg";
import { ReactComponent as VkIcon } from "../assets/images/icons/socials-vk.svg";
import { ReactComponent as XIcon } from "../assets/images/icons/socials-x.svg";
import { ReactComponent as YoutubeIcon } from "../assets/images/icons/socials-youtube.svg";
import { SocialsData } from "../types/socials";

export const socialsData: SocialsData[] = [
  {
    label: "Discord",
    icon: DiscordIcon,
    links: [
      {
        country: "CIS",
        language: "Русский",
        link: "https://discord.com/invite/onestate-cis",
      },
      {
        country: "USA",
        language: "English",
        link: "https://discord.com/invite/onestate-usa-964553277792460850",
      },
      {
        country: "EU",
        language: "English",
        link: "https://discord.com/invite/onestate-eu-964553655955103804",
      },
      {
        country: "United Kingdom",
        language: "English",
        link: "https://discord.com/invite/cAxzJGPuHV",
      },
      {
        country: "ASIA",
        language: "English",
        link: "https://discord.com/invite/xTjASMrgkY",
      },
      {
        country: "Africa",
        language: "English",
        link: "https://discord.com/invite/WH73dpaWsB",
      },
      {
        country: "Australia",
        language: "English",
        link: "https://discord.com/invite/EmZEwvQvZq",
      },
      {
        country: "Brazil",
        language: "Portugues",
        link: "https://discord.com/invite/onestate-brasil",
      },
      {
        country: "Germany",
        language: "Deutsch",
        link: "https://discord.com/invite/onestate-de-1090284279499600052",
      },
      {
        country: "France",
        language: "Français",
        link: "https://discord.com/invite/NP5NeQksfq",
      },
      {
        country: "Japan",
        language: "日本語",
        link: "https://discord.com/invite/z8JdVyCYfB",
      },
    ],
  },
  {
    label: "Facebook",
    icon: FacebookIcon,
    links: [
      {
        country: "USA",
        language: "English",
        link: "https://www.facebook.com/gaming/OneStateGame/",
      },
      {
        country: "Brazil",
        language: "Portugues",
        link: "https://www.facebook.com/gaming/OneStateBR/",
      },
      {
        country: "Germany",
        language: "Deutsch",
        link: "https://www.facebook.com/gaming/OneStateDE/",
      },
      {
        country: "France",
        language: "Français",
        link: "https://www.facebook.com/profile.php?id=61556336667240",
      },
      {
        country: "Japan",
        language: "日本語",
        link: "https://www.facebook.com/profile.php?id=61556010766904",
      },
      {
        country: "The Middle East",
        language: "العربية",
        link: "https://www.facebook.com/profile.php?id=61567719311883",
      },
    ],
  },
  {
    label: "Instagram",
    icon: InstagramIcon,
    links: [
      {
        country: "USA",
        language: "English",
        link: "https://www.instagram.com/onestate_game/",
      },
      {
        country: "Brazil",
        language: "Portugues",
        link: "https://www.instagram.com/onestate_bra/",
      },
      {
        country: "Russia",
        language: "Русский",
        link: "https://www.instagram.com/onestate_ru/2",
      },
      {
        country: "Japan",
        language: "日本語",
        link: "https://www.instagram.com/onestate_jpn/",
      },
      {
        country: "France",
        language: "Français",
        link: "https://www.instagram.com/onestate_fra/",
      },
      {
        country: "Germany",
        language: "Deutsch",
        link: "https://www.instagram.com/onestate_de/",
      },
      {
        country: "The Middle East",
        language: "العربية",
        link: "https://www.instagram.com/onestateme",
      },
    ],
  },
  {
    label: "YouTube",
    icon: YoutubeIcon,
    links: [
      {
        country: "USA",
        language: "English",
        link: "https://www.youtube.com/@ChillBaseStudio",
      },
      {
        country: "Brazil",
        language: "Portugues",
        link: "https://www.youtube.com/@OneState_BR",
      },
      {
        country: "CIS",
        language: "Русский",
        link: "https://www.youtube.com/@OneStateCIS",
      },
      {
        country: "Germany",
        language: "Deutsch",
        link: "https://www.youtube.com/@OneStateDE",
      },
    ],
  },
  {
    label: "Reddit",
    icon: RedditIcon,
    links: [
      {
        country: "USA",
        language: "English",
        link: "https://www.reddit.com/r/OneState_Game/?rdt=59057",
      },
    ],
  },
  {
    label: "VK",
    icon: VkIcon,
    links: [
      {
        country: "Russia",
        language: "Русский",
        link: "https://vk.com/onestaterp",
      },
    ],
  },
  {
    label: "X",
    icon: XIcon,
    links: [
      {
        country: "USA",
        language: "English",
        link: "https://x.com/OneStateGame",
      },
    ],
  },
  {
    label: "LinkedIn",
    icon: LinkedinIcon,
    links: [
      {
        country: "Global",
        language: "English",
        link: "https://www.linkedin.com/company/chillgaming-ltd/",
      },
    ],
  },
];
