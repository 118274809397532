import AfricaFlag from "../assets/images/icons/flag-africa.png";
import AsiaFlag from "../assets/images/icons/flag-asia.png";
import AustraliaFlag from "../assets/images/icons/flag-australia.png";
import BrazilFlag from "../assets/images/icons/flag-brazil.png";
import CISFlag from "../assets/images/icons/flag-cis.png";
import EUFlag from "../assets/images/icons/flag-eu.png";
import FranceFlag from "../assets/images/icons/flag-france.png";
import GermanyFlag from "../assets/images/icons/flag-germany.png";
import JapanFlag from "../assets/images/icons/flag-japan.png";
import MeFlag from "../assets/images/icons/flag-me.png";
import RussiaFlag from "../assets/images/icons/flag-russia.png";
import UnitedKingdomFlag from "../assets/images/icons/flag-unitedkingdom.png";
import USAFlag from "../assets/images/icons/flag-usa.png";

export const flags = {
  africa: AfricaFlag,
  asia: AsiaFlag,
  australia: AustraliaFlag,
  brazil: BrazilFlag,
  cis: CISFlag,
  eu: EUFlag,
  france: FranceFlag,
  germany: GermanyFlag,
  japan: JapanFlag,
  united_kingdom: UnitedKingdomFlag,
  usa: USAFlag,
  russia: RussiaFlag,
  the_middle_east: MeFlag,
};
