import { FC } from "react";
import ReactModal from "react-modal";

import { ReactComponent as CloseIcon } from "../../assets/images/icons/x-icon.svg";
import { ModalDoubleWindowTemplateProps } from "../../types/modals";
import Button from "../Button";
import styles from "./ModalDoubleWindowTemplate.module.sass";

ReactModal.setAppElement("#wrapper");

const ReactModalAny = ReactModal as any;

export const ModalDoubleWindowTemplate: FC<ModalDoubleWindowTemplateProps> = ({
  children,
  leftTitle,
  rightTitle,
  modalLeftImage,
  isOpen,
  onClose,
  alertBlock,
}) => {
  return (
    <ReactModalAny
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      ariaHideApp={false}
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "888" },
      }}
    >
      <div className="modal-wrapper">
        <Button className="modal-btn" onClick={onClose}>
          <CloseIcon className="modal-close-icon" />
        </Button>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <h2 className={"modal-title " + styles.leftTitle}>{leftTitle}</h2>
            <div className={styles.content}>
              <div className={styles.image}>
                <img
                  src={modalLeftImage}
                  alt="modal left img"
                  className="image"
                />
              </div>
              {alertBlock}
            </div>
          </div>
          <div className={styles.right}>
            <h2 className="modal-title">{rightTitle}</h2>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </ReactModalAny>
  );
};
